import { forEach } from './../polyfills/for-each';

const KEY_CODE_ESCAPE = 27;
const modalButtons = document.querySelectorAll('[data-toggle="modal"]');

const modalOverlay = document.querySelector('.modal-overlay');

forEach(modalButtons, (i, modalButton) => {
    modalButton.addEventListener('click', event => {
        modalButton.focus();
        modalButton.blur();

        let target = modalButton.getAttribute('data-target');
        let modal = document.querySelector(target);

        if (modal.style.display == 'flex') {
            modalOverlay.style.display = 'none';
            modal.style.display = 'none';
        } else {
            modalOverlay.style.display = 'flex';
            modal.style.display = 'flex';
        }
    });
});

const modals = document.querySelectorAll('.modal');

forEach(modals, (i, modal) => {
    modal.addEventListener('click', event => {
        if (event.target.classList.contains('modal')) {
            modalOverlay.style.display = 'none';
            modal.style.display = 'none';
        }
    });

    window.addEventListener('keyup', event => {
        if (event.keyCode === KEY_CODE_ESCAPE) {
            modalOverlay.style.display = 'none';
            modal.style.display = 'none';
        }
    });
});
