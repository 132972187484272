import { forEach } from './../polyfills/for-each';

const collapsibles = document.querySelectorAll('[data-toggle=collapse]');

forEach(collapsibles, (i, collapsible) => {
    collapsible.addEventListener('click', event => {
        collapsible.focus();
        collapsible.blur();

        let selector = collapsible.getAttribute('data-target');
        let target = document.querySelector(selector);

        if (target.style.display == 'block') {
            target.style.display = 'none';
            collapsible.classList.remove('open');
        } else {
            target.style.display = 'block';
            collapsible.classList.add('open');
        }
    });
});
